import React, { useContext, createContext } from "react";
import { useVisibility } from "../../contexts/visibilityContext";
import { useUser } from "../../contexts/userContext";
import ApiCaller from "../interceptor";
import toast from "react-hot-toast";
import { catchErrMsg } from "../../../utils/utility";
import axios from "axios";
import NotifierComponents from "react-pop-notifier";
import { useAppInfo } from "../../contexts/appInfoContext";

const ApiContext = createContext<any>(null);
const { useNotifier } = NotifierComponents;
const ApiProvider = (props: any) => {
  const { user, setUserProperty } = useUser();
  const { logout, setInfoProperty } = useAppInfo();
  const { setValue } = useAppInfo();
  const { loader } = useVisibility();
  const { notifier } = useNotifier();

  async function login(payload: any) {
    try {
      loader(true);
      const { data } = await ApiCaller.post("/auth/login/", payload);
      console.log(data);
      setUserProperty("profile", data);
      setUserProperty("token", data.data.token);
      setUserProperty("isAuth", data.status);
      loader(false);

      if (data.status === true) {
        notifier.show(data.msg, "Success", "success");
        return true;
      } else {
        notifier.show(data.msg, "Error", "error");
        return false;
      }
    } catch (err: any) {
      loader(false);
      notifier.show(catchErrMsg(err));
      console.log(err);
    }
  }

  async function register(payload: any) {
    try {
      loader(true);
      const { data } = await ApiCaller.post("/auth/register/", payload);
      console.log(data);
      loader(false);

      if (data.status === true) {
        notifier.show(data.msg, "Success", "success");
        return true;
      } else {
        notifier.show(data.msg, "Error", "error");
        return false;
      }
    } catch (err: any) {
      loader(false);
      if (err?.response?.status === 400) {
        if (
          err?.response?.data?.fields?.email[0] ===
          "custom user with this email already exists."
        ) {
          notifier.show("User with this email already exists");
        }
      } else {
        notifier.show(err?.response?.data?.msg);
        console.log(err);
      }
    }
  }
  async function verifyCode(payload: any) {
    try {
      loader(true);
      const { data } = await ApiCaller.post("auth/code/verify/", payload);
      console.log(data);
      loader(false);

      if (data.status === true) {
        notifier.show(data.msg, "Success", "success");
        return true;
      } else {
        notifier.show(data.msg);
        return false;
      }
    } catch (err: any) {
      loader(false);
      notifier.show("An error occurred");
      console.log(err);
    }
  }
  async function resendCode(payload: any) {
    try {
      loader(true);
      const { data } = await ApiCaller.post("auth/code/verify/", payload);
      console.log(data);
      loader(false);

      if (data.status === true) {
        notifier.show(data.msg, "Success", "success");
        return true;
      } else {
        notifier.show(data.msg);
        return false;
      }
    } catch (err: any) {
      loader(false);
      notifier.show("An error occurred");
      console.log(err);
    }
  }
  async function getDataControls() {
    try {
      loader(true);
      const { data } = await ApiCaller.get("core/customer/fetch/data/");
      console.log(data);
      loader(false);
      setValue("dataControl", data.data);

      if (data.status === true) {
        notifier.show(data.msg, "Success", "success");
        return true;
      } else {
        notifier.show(data.msg);
        return false;
      }
    } catch (err: any) {
      loader(false);
      if (err?.response?.status === 401) {
        if (
          err?.response?.data?.msg ===
            "Your session has expired. Kindly sign-in again." ||
          err?.response?.data?.msg ===
            "Session Expired. Please try signing again."
        ) {
          logout();
          window.location.href = "/auth";
          notifier.show(
            "Your session has expired. Kindly sign-in again.",
            "Error",
            "error"
          );
        }
      } else {
        notifier.show(err?.response?.data?.msg);
        console.log(err);
      }
    }
  }
  async function getDataPlans(id: any) {
    try {
      loader(true);
      const { data } = await ApiCaller.get(`core/customer/fetch/data/${id}`);
      console.log(data);
      loader(false);
      setValue("dataPlans", data.data);

      if (data.status === true) {
        notifier.show(data.msg, "Success", "success");
        return true;
      } else {
        notifier.show(data.msg);
        return false;
      }
    } catch (err: any) {
      loader(false);
      if (err?.response?.status === 401) {
        if (
          err?.response?.data?.msg ===
            "Your session has expired. Kindly sign-in again." ||
          err?.response?.data?.msg ===
            "Session Expired. Please try signing again."
        ) {
          logout();
          notifier.show(
            "Your session has expired. Kindly sign-in again.",
            "Error",
            "error"
          );
          window.location.href = "/auth";
        }
      } else {
        notifier.show(err?.response?.data?.msg);
        console.log(err);
      }
    }
  }
  async function buyData(payload: any) {
    try {
      loader(true);
      const { data } = await ApiCaller.post("core/customer/data-bundle/", payload);
      console.log(data);
      loader(false);
      if (data.status === true) {
        notifier.show(data.msg, "Success", "success");
        return true;
      } else {
        notifier.show(data.msg);
        return false;
      }
    } catch (err: any) {
      loader(false);
      if (err?.response?.status === 401) {
        if (
          err?.response?.data?.msg ===
            "Your session has expired. Kindly sign-in again." ||
          err?.response?.data?.msg ===
            "Session Expired. Please try signing again."
        ) {
          logout();
          notifier.show(
            "Your session has expired. Kindly sign-in again.",
            "Error",
            "error"
          );
          window.location.href = "/auth";
        }
      } else {
        notifier.show(err?.response?.data?.msg);
        console.log(err);
      }
    }
  }


  async function getAirtime() {
    try {
      loader(true);
      const { data } = await ApiCaller.get("core/customer/fetch/airtime/");
      console.log(data);
      loader(false);
      
      if (data.status === true) {
        setValue("airtimeControl", data.data);
        notifier.show(data.msg, "Success", "success");
        return true;
      } else {
        notifier.show(data.msg);
        return false;
      }
    } catch (err: any) {
      loader(false);
      if (err?.response?.status === 401) {
        if (
          err?.response?.data?.msg ===
            "Your session has expired. Kindly sign-in again." ||
          err?.response?.data?.msg ===
            "Session Expired. Please try signing again."
        ) {
          logout();
          window.location.href = "/auth";
          notifier.show(
            "Your session has expired. Kindly sign-in again.",
            "Error",
            "error"
          );
        }
      } else {
        notifier.show(err?.response?.data?.msg);
        console.log(err);
      }
    }
  }
  async function buyAirtime(payload:any) {
    try {
      loader(true);
      const { data } = await ApiCaller.post("core/customer/airtime/", payload);
      console.log(data);
      loader(false);
      
      if (data.status === true) {
        notifier.show(data.msg, "Success", "success");
        return true;
      } else {
        notifier.show(data.msg);
        return false;
      }
    } catch (err: any) {
      loader(false);
      if (err?.response?.status === 401) {
        if (
          err?.response?.data?.msg ===
            "Your session has expired. Kindly sign-in again." ||
          err?.response?.data?.msg ===
            "Session Expired. Please try signing again."
        ) {
          logout();
          window.location.href = "/auth";
          notifier.show(
            "Your session has expired. Kindly sign-in again.",
            "Error",
            "error"
          );
        }
      } else {
        notifier.show(err?.response?.data?.msg);
        console.log(err);
      }
    }
  }
  async function getAirtimeHistory() {
    try {
      loader(true);
      const { data } = await ApiCaller.get("core/customer/airtime/");
      console.log(data);
      setValue('airtimeHistory', data)
      loader(false);
      
      if (data.status === true) {
        setValue('airtimeHistory', data?.data)
        notifier.show(data.msg, "Success", "success");
        return true;
      } else {
        notifier.show(data.msg);
        return false;
      }
    } catch (err: any) {
      loader(false);
      if (err?.response?.status === 401) {
        if (
          err?.response?.data?.msg ===
            "Your session has expired. Kindly sign-in again." ||
          err?.response?.data?.msg ===
            "Session Expired. Please try signing again."
        ) {
          logout();
          window.location.href = "/auth";
          notifier.show(
            "Your session has expired. Kindly sign-in again.",
            "Error",
            "error"
          );
        }
      } else {
        notifier.show(err?.response?.data?.msg);
        console.log(err);
      }
    }
  }

  async function getDataTransactions(id: any) {
    try {
      loader(true);
      const { data } = await ApiCaller.get("core/customer/data-bundle/");
      console.log(data);
      setValue('dataHistory', data.data)
      loader(false);
      // setValue("dataPlans", data.data);

      if (data.status === true) {
        notifier.show(data.msg, "Success", "success");
        return true;
      } else {
        notifier.show(data.msg);
        return false;
      }
    } catch (err: any) {
      loader(false);
      if (err?.response?.status === 401) {
        if (
          err?.response?.data?.msg ===
            "Your session has expired. Kindly sign-in again." ||
          err?.response?.data?.msg ===
            "Session Expired. Please try signing again."
        ) {
          logout();
          window.location.href = "/auth";
          notifier.show(
            "Your session has expired. Kindly sign-in again.",
            "Error",
            "error"
          );
        }
      } else {
        notifier.show(err?.response?.data?.msg);
        console.log(err);
      }
    }
  }
  async function getCableTvControls() {
    try {
      loader(true);
      const { data } = await ApiCaller.get("core/customer/fetch/tv/");
      console.log(data);
      loader(false);
      setValue("cableControl", data.data);

      if (data.status === true) {
        notifier.show(data.msg, "Success", "success");
        return true;
      } else {
        notifier.show(data.msg);
        return false;
      }
    } catch (err: any) {  
      loader(false);
      if (err?.response?.status === 401) {
        if (
          err?.response?.data?.msg ===
            "Your session has expired. Kindly sign-in again." ||
          err?.response?.data?.msg ===
            "Session Expired. Please try signing again."
        ) {
          logout();
          window.location.href = "/auth";
          notifier.show(
            "Your session has expired. Kindly sign-in again.",
            "Error",
            "error"
          );
        }
      } else {
        notifier.show(err?.response?.data?.msg);
        console.log(err);
      }
    }
  }
  async function getCableTvPlans(id:any) {
    try {
      loader(true);
      const { data } = await ApiCaller.get(`core/customer/fetch/tv/${id}`);
      console.log(data);
      loader(false);
      setValue("cableTvPlans", data.data);

      if (data.status === true) {
        notifier.show(data.msg, "Success", "success");
        return true;
      } else {
        notifier.show(data.msg);
        return false;
      }
    } catch (err: any) {  
      loader(false);
      if (err?.response?.status === 401) {
        if (
          err?.response?.data?.msg ===
            "Your session has expired. Kindly sign-in again." ||
          err?.response?.data?.msg ===
            "Session Expired. Please try signing again."
        ) {
          logout();
          window.location.href = "/auth";
          notifier.show(
            "Your session has expired. Kindly sign-in again.",
            "Error",
            "error"
          );
        }
      } else {
        notifier.show(err?.response?.data?.msg);
        console.log(err);
      }
    }
  }

async function getElectricityControls(id: any) {
  try {
    loader(true);
    const { data } = await ApiCaller.get("core/customer/fetch/electricity/");
    console.log(data);
    loader(false);
    setValue("electricityControl", data.data);

    if (data.status === true) {
      notifier.show(data.msg, "Success", "success");
      return true;
    } else {
      notifier.show(data.msg);
      return false;
    }
  } catch (err: any) {
    loader(false);
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.msg ===
          "Your session has expired. Kindly sign-in again." ||
        err?.response?.data?.msg ===
          "Session Expired. Please try signing again."
      ) {
        logout();
        window.location.href = "/auth";
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
      }
    } else {
      notifier.show(err?.response?.data?.msg);
      console.log(err);
    }
  }
}


  async function getProfile() {
    try {
      loader(true);
      const { data } = await ApiCaller.get("auth/profile/");
      console.log(data);
      loader(false);
      

      if (data.status === true) {
        notifier.show(data.msg, "Success", "success");
        setUserProperty("profile", data.data);
        return true;
      } else {
        notifier.show(data.msg);
        return false;
      }
    } catch (err: any) {  
      loader(false);
      if (err?.response?.status === 401) {
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
        logout();
        window.location.href = "/auth";
      } else {
        notifier.show(err?.response?.data?.msg);
        console.log(err);
      }
    }
  }

async function resetPin(id: any) {
  try {
    loader(true);
    const { data } = await ApiCaller.post("customer/pin-reset/request/");
    console.log(data);
    loader(false);

    if (data.status === true) {
      notifier.show(data.msg, "Success", "success");
      return true;
    } else {
      notifier.show(data.msg);
      return false;
    }
  } catch (err: any) {
    loader(false);
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.msg ===
          "Your session has expired. Kindly sign-in again." ||
        err?.response?.data?.msg ===
          "Session Expired. Please try signing again."
      ) {
        logout();
        window.location.href = "/auth";
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
      }
    } else {
      notifier.show(err?.response?.data?.msg);
      console.log(err);
    }
  }
}



async function confirmResetPin(payload:any) {
  try {
    loader(true);
    const { data } = await ApiCaller.post("customer/pin-reset/confirm/", payload);
    console.log(data);
    loader(false);

    if (data.status === true) {
      notifier.show(data.msg, "Success", "success");
      return true;
    } else {
      notifier.show(data.msg);
      return false;
    }
  } catch (err: any) {
    loader(false);
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.msg ===
          "Your session has expired. Kindly sign-in again." ||
        err?.response?.data?.msg ===
          "Session Expired. Please try signing again."
      ) {
        logout();
        window.location.href = "/auth";
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
      }
    } else {
      notifier.show(err?.response?.data?.msg);
      console.log(err);
    }
  }
}
async function verifyElectricity(payload:any) {
  try {
    loader(true);
    const { data } = await ApiCaller.post(
      "core/customer/electricity/",
      payload
    );
    console.log(data);
    loader(false);

    if (data.status === true) {
      notifier.show(data.msg, "Success", "success");
      return true;
    } else {
      notifier.show(data.msg);
      return false;
    }
  } catch (err: any) {
    loader(false);
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.msg ===
          "Your session has expired. Kindly sign-in again." ||
        err?.response?.data?.msg ===
          "Session Expired. Please try signing again."
      ) {
        logout();
        window.location.href = "/auth";
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
      }
    } else {
      notifier.show(err?.response?.data?.msg);
      console.log(err);
    }
  }
}


async function buyElectricity(payload: any) {
  try {
    loader(true);
    const { data } = await ApiCaller.post(
      "core/customer/electricity/",
      payload
    );
    console.log(data);
    loader(false);

    if (data.status === true) {
      notifier.show(data.msg, "Success", "success");
      return true;
    } else {
      notifier.show(data.msg);
      return false;
    }
  } catch (err: any) {
    loader(false);
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.msg ===
          "Your session has expired. Kindly sign-in again." ||
        err?.response?.data?.msg ===
          "Session Expired. Please try signing again."
      ) {
        logout();
        window.location.href = "/auth";
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
      }
    } else {
      notifier.show(err?.response?.data?.msg);
      console.log(err);
    }
  }
}

async function verifyTv(payload: any) {
  try {
    loader(true);
    const { data } = await ApiCaller.post(
      "core/customer/tv/",
      payload
    );
    console.log(data);
    loader(false);

    if (data.status === true) {
      notifier.show(data.msg, "Success", "success");
      return true;
    } else {
      notifier.show(data.msg);
      return false;
    }
  } catch (err: any) {
    loader(false);
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.msg ===
          "Your session has expired. Kindly sign-in again." ||
        err?.response?.data?.msg ===
          "Session Expired. Please try signing again."
      ) {
        logout();
        window.location.href = "/auth";
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
      }
    } else {
      notifier.show(err?.response?.data?.msg);
      console.log(err);
    }
  }
}


async function getUsers() {
  try {
    loader(true);
    const { data } = await ApiCaller.get("sys.admin/users/");
    console.log(data);
    loader(false);
    setValue("allUsers", data.data);

    if (data.status === true) {
      notifier.show(data.msg, "Success", "success");
      return true;
    } else {
      notifier.show(data.msg);
      return false;
    }
  } catch (err: any) {
    loader(false);
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.msg ===
          "Your session has expired. Kindly sign-in again." ||
        err?.response?.data?.msg ===
          "Session Expired. Please try signing again."
      ) {
        logout();
        window.location.href = "/auth";
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
      }
    } else {
      notifier.show(err?.response?.data?.msg);
      console.log(err);
    }
  }
}

async function getAUser(id:any) {
  try {
    loader(true);
    const { data } = await ApiCaller.get(`sys.admin/users/${id}`);
    console.log(data);
    loader(false);
    setInfoProperty("user", data.data);

    if (data.status === true) {
      notifier.show(data.msg, "Success", "success");
      return true;
    } else {
      notifier.show(data.msg);
      return false;
    }
  } catch (err: any) {
    loader(false);
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.msg ===
          "Your session has expired. Kindly sign-in again." ||
        err?.response?.data?.msg ===
          "Session Expired. Please try signing again."
      ) {
        logout();
        window.location.href = "/auth";
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
      }
    } else {
      notifier.show(err?.response?.data?.msg);
      console.log(err);
    }
  }
}

async function getDashboard() {
  try {
    loader(true);
    const { data } = await ApiCaller.get("sys.admin/dashboard/");
    console.log(data);
    setValue('dashboardData', data?.data)
    loader(false);
    

    if (data.status === true) {
      notifier.show(data.msg, "Success", "success");
      return true;
    } else {
      notifier.show(data.msg);
      return false;
    }
  } catch (err: any) {
    loader(false);
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.msg ===
          "Your session has expired. Kindly sign-in again." ||
        err?.response?.data?.msg ===
          "Session Expired. Please try signing again."
      ) {
        logout();
        window.location.href = "/auth";
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
      }
    } else {
      notifier.show(err?.response?.data?.msg);
      console.log(err);
    }
  }
}


async function adminFundWallet(payload:any, id:any) {
  try {
    loader(true);
    const { data } = await ApiCaller.post(`/staff/customer/fund/${id}/`, payload);
    console.log(data);
    loader(false);
   

    if (data.status === true) {
      notifier.show(data.msg, "Success", "success");
      return true;
    } else {
      notifier.show(data.msg);
      return false;
    }
  } catch (err: any) {
    loader(false);
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.msg ===
          "Your session has expired. Kindly sign-in again." ||
        err?.response?.data?.msg ===
          "Session Expired. Please try signing again."
      ) {
        logout();
        window.location.href = "/auth";
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
      }
    } else {
      notifier.show(err?.response?.data?.msg);
      console.log(err);
    }
  }
}

async function getEmergencyStatus() {
  try {
    loader(true);
    const { data } = await ApiCaller.get("sys.admin/emergency-mode/");
    console.log(data);
    setValue('emergencyStatus', data?.msg)
    loader(false);

    if (data.status === true) {
      notifier.show(data.msg, "Success", "success");
      return true;
    } else {
      notifier.show(data.msg);
      return false;
    }
  } catch (err: any) {
    loader(false);
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.msg ===
          "Your session has expired. Kindly sign-in again." ||
        err?.response?.data?.msg ===
          "Session Expired. Please try signing again."
      ) {
        logout();
        window.location.href = "/auth";
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
      }
    } else {
      notifier.show(err?.response?.data?.msg);
      console.log(err);
    }
  }
}

async function updateEmergency(payload:any) {
  try {
    loader(true);
    const { data } = await ApiCaller.post("sys.admin/emergency-mode/", payload);
    console.log(data);
    loader(false);

    if (data.status === true) {
      notifier.show(data.msg, "Success", "success");
      return true;
    } else {
      notifier.show(data.msg);
      return false;
    }
  } catch (err: any) {
    loader(false);
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.msg ===
          "Your session has expired. Kindly sign-in again." ||
        err?.response?.data?.msg ===
          "Session Expired. Please try signing again."
      ) {
        logout();
        window.location.href = "/auth";
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
      }
    } else {
      notifier.show(err?.response?.data?.msg);
      console.log(err);
    }
  }
}
async function updateTransactions(id:any, payload:any) {
  try {
    loader(true);
    const { data } = await ApiCaller.patch(`sys.admin/users/${id}/`, payload);
    console.log(data);
    loader(false);

    if (data.status === true) {
      notifier.show(data.msg, "Success", "success");
      return true;
    } else {
      notifier.show(data.msg);
      return false;
    }
  } catch (err: any) {
    loader(false);
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.msg ===
          "Your session has expired. Kindly sign-in again." ||
        err?.response?.data?.msg ===
          "Session Expired. Please try signing again."
      ) {
        logout();
        window.location.href = "/auth";
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
      }
    } else {
      notifier.show(err?.response?.data?.msg);
      console.log(err);
    }
  }
}

async function getStats() {
  try {
    loader(true);
    const { data } = await ApiCaller.get("core/staff/statistics/");
    console.log(data);
    loader(false);

    if (data.status === true) {
      notifier.show(data.msg, "Success", "success");
      return true;
    } else {
      notifier.show(data.msg);
      return false;
    }
  } catch (err: any) {
    loader(false);
    if (err?.response?.status === 401) {
      if (
        err?.response?.data?.msg ===
          "Your session has expired. Kindly sign-in again." ||
        err?.response?.data?.msg ===
          "Session Expired. Please try signing again."
      ) {
        logout();
        window.location.href = "/auth";
        notifier.show(
          "Your session has expired. Kindly sign-in again.",
          "Error",
          "error"
        );
      }
    } else {
      notifier.show(err?.response?.data?.msg);
      console.log(err);
    }
  }
}

  const callActions = {
    login,
    register,
    verifyCode,
    resendCode,
    getDataControls,
    getDataPlans,
    getDataTransactions,
    getCableTvControls,
    getAirtime,
    buyAirtime,
    getAirtimeHistory,
    getProfile,
    getCableTvPlans,
    getElectricityControls,
    buyData,
    resetPin,
    confirmResetPin,
    verifyElectricity,
    buyElectricity,
    verifyTv,
    getUsers,
    getDashboard,
    getAUser,
    adminFundWallet,
    getEmergencyStatus,
    updateEmergency,
    updateTransactions,
    getStats
  };

  return (
    <ApiContext.Provider value={{ API: callActions }}>
      {props.children}
    </ApiContext.Provider>
  );
};

function useApi() {
  const context = useContext(ApiContext);
  if (context === undefined)
    throw new Error("ApiContext was used outside ApiProvider");
  return context;
}
export { useApi, ApiProvider };
