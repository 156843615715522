import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../../../assets/img/Horizontal-Logo-White.png";
import { MdDashboard, MdOutlineHistory } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import { TbWorld } from "react-icons/tb";
import { BiLogOut } from "react-icons/bi";
import Modal from "../../../Modal";
import { Button } from "../../../Button";
import { useState } from "react";
import { useAppInfo } from "../../../../provider/contexts/appInfoContext";
import { AiOutlineAudit } from "react-icons/ai";
export const AdminSidebar = () => {
  const [open, setIsOpen] = useState(false);
  const { logout } = useAppInfo();
  const navigate = useNavigate();

  function handleCancel() {
    setIsOpen(false);
  }

  function handleLogout() {
    logout();
    navigate("/auth");
  }
  return (
    <>
      <div className="flex flex-col gap-10  py-10">
        <img src={Logo} alt="" className="w-[13rem] inline-block mx-auto" />

        <nav>
          <ul className="flex flex-col gap-5 font-fontMont dashboard-nav">
            <li>
              <NavLink
                to="/admin/dashboard"
                className="flex items-center font-semibold gap-5 px-8 py-5 rounded-tr-lg rounded-br-lg duration-300 hover:bg-[#191919] text-white hover:text-primaryPurple "
              >
                <MdDashboard className="text-white" />
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/manage-users"
                className="flex items-center font-semibold gap-5 px-8 py-5 rounded-tr-lg rounded-br-lg  duration-300 hover:bg-[#191919] text-white hover:text-primaryPurple"
              >
                <IoWalletOutline className="text-white" /> Manage Users
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/transactions"
                className="flex items-center font-semibold gap-5 px-8 py-5 rounded-tr-lg rounded-br-lg duration-300 hover:bg-[#191919] text-white hover:text-primaryPurple"
              >
                <MdOutlineHistory className="text-white" />
                Transactions
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/revenue"
                className="flex items-center font-semibold gap-5 px-8 py-5 rounded-tr-lg rounded-br-lg duration-300 hover:bg-[#191919] text-white hover:text-primaryPurple"
              >
                <MdOutlineHistory className="text-white" />
                Revenue Stream
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/audit"
                className="flex items-center font-semibold gap-5 px-8 py-5 rounded-tr-lg rounded-br-lg duration-300 hover:bg-[#191919] text-white hover:text-primaryPurple"
              >
                <AiOutlineAudit className="text-white" />
                Audit Log
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/referrals"
                className="flex items-center font-semibold gap-5 px-8 py-5 rounded-tr-lg rounded-br-lg duration-300 hover:bg-[#191919] text-white hover:text-primaryPurple"
              >
                <TbWorld className="text-white" />
                Referrals
              </NavLink>
            </li>

            <li>
              <button
                onClick={() => setIsOpen(true)}
                className="flex items-center w-full font-semibold gap-5 px-8 py-5 rounded-tr-lg border-none bg-transparent rounded-br-lg duration-300 hover:bg-[#191919] text-white hover:text-primaryPurple"
              >
                <BiLogOut className="text-white" />
                Logout
              </button>
            </li>
          </ul>
        </nav>
        <Modal
          open={open}
          cancel={handleCancel}
          className="bg-[#111111] p-5 rounded-xl"
        >
          <div className="space-y-20">
            <p className="text-lg text-white font-fontMont font-normal">
              You are about to log out. Do you want to proceed?
            </p>
            <div className="flex gap-10 justify-center">
              <Button as="btn" className="w-32" onClick={handleLogout}>
                Log out
              </Button>
              <Button
                as="btn"
                bgColor="bg-black"
                className="border border-borderColor w-32"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
